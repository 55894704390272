import validate from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import expivi_45load_45delay_45global from "/buddy/roastar-combined/nuxt/middleware/expivi-load-delay.global.ts";
import legacy_45quote_45links_45global from "/buddy/roastar-combined/nuxt/middleware/legacy-quote-links.global.ts";
import previous_45route_45global from "/buddy/roastar-combined/nuxt/middleware/previous-route.global.ts";
import redirects_45global from "/buddy/roastar-combined/nuxt/middleware/redirects.global.ts";
export const globalMiddleware = [
  validate,
  expivi_45load_45delay_45global,
  legacy_45quote_45links_45global,
  previous_45route_45global,
  redirects_45global
]
export const namedMiddleware = {
  auth: () => import("/buddy/roastar-combined/nuxt/middleware/auth.ts"),
  guest: () => import("/buddy/roastar-combined/nuxt/middleware/guest.ts")
}